import React from 'react';

const LoadingPageLayout = () => {
    return (
        <div className='w-[100dvw] h-[100dvh] bg-black flex items-center justify-center'>
            <img src='/images/logo-main.png' alt='logo' width={'80px'} />
        </div>
    );
};

export default LoadingPageLayout;
