import { Suspense } from 'react';
import LoadingPageLayout from './layouts/loading-page';
import AppRouter from './routing/router';

function App() {
    return (
        <Suspense fallback={<LoadingPageLayout />}>
            <AppRouter />
        </Suspense>
    );
}

export default App;
