import germanContents from './de/index.json';
import englishContents from './en/index.json';

export const resources = {
    en: {
        translation: englishContents,
    },
    de: {
        translation: germanContents,
    },
};
