import { Suspense, lazy } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoadingPageLayout from '../layouts/loading-page';
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const LandingPage = lazy(() => import('../pages/LandingPage'));

const AppRouter = () => {
    return (
        <Router>
            <Suspense fallback={<LoadingPageLayout />}>
                <Routes>
                    {/* NOT FOUND/ INVALID ROUTE */}
                    <Route path='*' element={<PageNotFound />} />

                    {/* MAIN PAGE */}
                    <Route path='/' element={<LandingPage />} />
                </Routes>
            </Suspense>
        </Router>
    );
};

export default AppRouter;
