import i18n from 'i18next';
import Languagedetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { resources } from '../translations';

i18n.use(Languagedetector).use(initReactI18next).init({
    lng: 'de',
    fallbackLng: 'en',
    debug: true,
    resources,
});

export default i18n;
